import React from "react";

/**
 * Not Found 페이지
 */
const NotFoundPage = () => {

    return (
      <>
      </>
    );
};

export default NotFoundPage;